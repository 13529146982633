<template>
  <div class="card">
    <div class="card-body">
      <AccountApi />
    </div>
  </div>
</template>

<script>
import AccountApi from '@/components/Account/Api/Api.vue'

export default {
  components: { AccountApi }
}
</script>
