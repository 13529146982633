<template>
  <div>
    <div class="upgrade-title">Upgrade</div>
    <div class="upgrade">
      <div class="upgrade-text">{{ text }}</div>
      <base-button color="upgrade-button" @click="$router.push({ name: 'account.subscription' })">
        Upgrade Now
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpgradeBadge',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    isSubscribed () {
      return this.user.subscription_plan && this.user.subscription_plan.name !== 'Free'
    },
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style scoped lang="scss">
.upgrade {
  max-width: 300px;
  width: 100%;
  padding: 28px 24px;
  background: #219653;
  border: 1px solid #E9EBEC;
  box-shadow: 0 2px 4px rgba(25, 34, 29, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    font-size: 18px;
    height: auto;
    font-weight: 600;
    letter-spacing: -0.017em;
    margin-bottom: 16px;
  }
  &-text {
    text-align: center;
    color: white;
  }
  &-button {
    margin-top: 19px;
    ::v-deep {
      .content {
        color: #219653 !important;
      }
    }
  }
}
</style>
